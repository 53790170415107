import React, { Component } from 'react'

export class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <header>
                    <div className="app-header">

                    </div>
                </header>
            </React.Fragment>
        )
    }
}

export default Header
